import React from "react";
import { Route, Switch } from "react-router-dom";
import Main from "./main/index";
import ResetPassword from "./userAuth/ResetPassword/index";

import ManageProjects1 from "./master/projects/manage/index";
import EditProjects1 from "./master/projects/manage/EditProject";
import NewProjects1 from "./master/projects/new/index";

import ManageTasks1 from "./master/tasks/manage/index";
import EditTasks1 from "./master/tasks/manage/EditTask";
import NewTasks1 from "./master/tasks/new/NewTask";

import EditClients1 from "./master/clients/manage/EditClient";
import ManageClients1 from "./master/clients/manage/index";
import NewClients1 from "./master/clients/new/NewClient";

import ManageResource1 from "./master/orgstructure/manage/editResource";
import ManageMember1 from "./master/orgstructure/manage/index";
import NewMember1 from "./master/orgstructure/new/index";

import ManageResource2 from "./orgstructure/manage/editResource";
import ManageMember2 from "./orgstructure/manage/index";
import NewMember2 from "./orgstructure/new/index";

import ManageTasks2 from "./master/executive/tasks/manage/index";

import ManageTasks from "./tasks/manage/index";
import EditTasks from "./tasks/manage/EditTask";
import NewTasks from "./tasks/new/NewTask";

import SkillMgr from "./skillMgr/index";
import ManageSkills from "./skillMgr/skill/manage/index";
import NewSkill from "./skillMgr/skill/new/index";

import NewTeam from "./skillMgr/team/new/index";
import ManageTeam from "./skillMgr/team/manage/index";

import NewQualifications from "./skillMgr/qualifications/new/index";
import ManageQualifications from "./skillMgr/qualifications/manage/index";

import MyAppsHome from "./dashboard/home/index";
import ExecDashboard from "./dashboard/executive/index";
import PMODashboard from "./dashboard/pmo/index";
import ManagerDashboard from "./dashboard/manager/index";
import EmployeeDashboard from "./dashboard/employee/index";

import NewSubmitTime from "./timesheets/submit/new/index";
import SubmitTime from "./timesheets/submit/index";
import ApproveTime from "./timesheets/approve/index";
import RMApproveTime from "./timesheets/rmapprove/index";
import SubmitTimeById from "./timesheets/submit/by-id/index";
import RMApproveTimeById from "./timesheets/rmapprove/by-id/index";

import ManageWSR from "./weeklystatus/manage/index";
import EditWSR from "./weeklystatus/manage/EditWSR";
import NewWSR from "./weeklystatus/new/NewWSR";

import ManageIssue from "./issues/manage/index";
import EditIssue from "./issues/manage/EditIssue";
import NewIssue from "./issues/new/NewIssue";

import ManageProjEfforts from "./master/projects/efforts/manage/index";
import EditPlannedEfforts from "./master/projects/efforts/manage/EditEfforts";
import NewProjEfforts from "./master/projects/efforts/new/index";

import ManageResPlan from "./master/projects/resourceplan/manage/index";
import EditResPlan from "./master/projects/resourceplan/manage/EditRescPlan";
import NewResPlan from "./master/projects/resourceplan/new/index";

import ManageProjPlan from "./master/projects/projplan/manage/index";
import EditProjPlan from "./master/projects/projplan/manage/EditProjPlan";
import NewProjPlan from "./master/projects/projplan/new/index";

import ManageRescAlloc from "./resources/manage/index";
import EditRescAlloc from "./resources/manage/EditResAlloc";
import NewRescAlloc from "./resources/new/NewResAlloc";

import ManageCost from "./cost/manage/index";
import EditCost from "./cost/manage/EditCost";
import NewCost from "./cost/new/NewCost";

import ManagePlan from "./plan/manage/index";
import EditPlan from "./plan/manage/EditPlan";
import NewPlan from "./plan/new/NewPlan";

import ProjectsReport from "./reports/project/index";
import ProjectById from "./reports/project/by-id/index";
import WSRReport from "./reports/wsr/index";
import IssuesReport from "./reports/issue/index";
import RescAllocReport from "./reports/resource/index";
import CostReport from "./reports/cost/index";
import PlanReport from "./reports/plan/index";
import PlanGantt from "./reports/plan/planGantt";
import TasksReport from "./reports/task/index";
import TimelogReport from "./reports/timesheet/timelog/index";
import PmoTimelog from "./dashboard/pmo/reports/timelog/index";
import MgrTimelog from "./dashboard/manager/reports/timelog/index";
import TSReport from "./reports/timesheet/index";
import ClientsReport from "./reports/client/index";
import OrgStructureReport from "./reports/orgstructure/index";

import ResourceUtil from "./resourceloading/utilization/index";
import ResourceAlloc from "./resourceloading/allocation/index";
import ProjectResources from "./master/projectResource/index";
import ResetPasswordByAdmin from "./userAuth/ResetPasswordByAdmin";

import TravelDeskRequest from "./travel_desk/new";
import TravelDeskPMApprove from "./travel_desk/pm-approve";
import TravelDeskRMApprove from "./travel_desk/rm-approve";
import TravelAdminApprove from "./travel_desk/admin-approve";

import TimeSheetStatusChange from "./master/timesheet";
import RMDashboardTasks from "./dashboard/rmmanager/tasks";
import RMDashboardProjects from "./dashboard/rmmanager/projects";

const UnAuthorized = () => {
  return <>
  <div className="oops-center">
  <img src={require("../assets/images/oops.jpg")} alt='OOPS!'/>
  <div><b>You don't have the permission to access this page.</b></div>
  <p>Please contact the Admin in case you need to access.</p>
  </div>
  </>
}

const App = ({ match }) => {
  const userRoles = JSON.parse(sessionStorage.getItem("user_Roles"));
  const userDetails = JSON.parse(sessionStorage.getItem("user_details"));
  return (
    <div className="gx-main-content-wrapper full-width gx-pt-0">
    <Switch>
      <Route path={`${match.url}main`} component={Main} />
      <Route path={`${match.url}userAuth/ResetPassword`} component={ResetPassword} />

      {userRoles.some((role) => role.id === 2 || role.id === 9999) && <Route path="/master/projects/manage/:id" component={EditProjects1} />}
      {userRoles.some((role) => role.id === 2 || role.id === 9999) && <Route path={`${match.url}master/projects/manage`} component={ManageProjects1} />}
      {userRoles.some((role) => role.id === 2) && <Route path={`${match.url}master/projects/new`} component={NewProjects1} />}

      { userRoles.some((role) => role.id === 2 || role.id === 9999) && <Route path="/master/tasks/manage/:id" component={EditTasks1} />}
      { userRoles.some((role) => role.id === 2 || role.id === 9999) && <Route path={`${match.url}master/tasks/manage`} component={ManageTasks1} />}
      { userRoles.some((role) => role.id === 2 || role.id === 9999) && <Route path={`${match.url}master/tasks/new`} component={NewTasks1} />}

      { userRoles.some((role) => role.id === 3) && <Route path="/tasks/manage/:id" component={EditTasks} />}
      { userRoles.some((role) => role.id === 3) && <Route path={`${match.url}tasks/manage`} component={ManageTasks} />}
      <Route path={`${match.url}tasks/new`} component={NewTasks} />

      { userRoles.some((role) => role.id === 9999) && <Route path="/master/clients/manage/:id" component={EditClients1} />}
      { userRoles.some((role) => role.id === 9999) && <Route path={`${match.url}master/clients/manage`} component={ManageClients1} />}
      { userRoles.some((role) => role.id === 9999) && <Route path={`${match.url}master/clients/new`} component={NewClients1} />}

      <Route path={`${match.url}master/executive/tasks/manage`} component={ManageTasks2} />
      { userRoles.some((role) => role.id === 2) && <Route path='/master/projectresource' component={ProjectResources} />}

      { userRoles.some((role) => role.id === 9999) && <Route path="/master/orgstructure/manage/:id" component={ManageResource1} />}
      { userRoles.some((role) => role.id === 9999) && <Route path={`${match.url}master/orgstructure/manage`} component={ManageMember1} />}
      { userRoles.some((role) => role.id === 9999) && <Route path={`${match.url}master/orgstructure/new`} component={NewMember1} />}

      { userRoles.some((role) => role.id === 2) && <Route path={`${match.url}orgstructure/manage/:id`} component={ManageResource2} />}
      { userRoles.some((role) => role.id === 2) && <Route path={`${match.url}orgstructure/manage`} component={ManageMember2} />}
      { userRoles.some((role) => role.id === 2) && <Route path={`${match.url}orgstructure/new`} component={NewMember2} />}

      { userRoles.some((role) => role.id === 2) && <Route path={`${match.url}skillMgr/`} component={SkillMgr} />}
      <Route path={`${match.url}skillMgr/skill/manage`} component={ManageSkills} />
      <Route path={`${match.url}skillMgr/skill/new`} component={NewSkill} />

      <Route path={`${match.url}skillMgr/team/new`} component={NewTeam} />
      <Route path={`${match.url}skillMgr/team/manage`} component={ManageTeam} />

      <Route path={`${match.url}skillMgr/qualifications/new`} component={NewQualifications} />
      <Route path={`${match.url}skillMgr/qualifications/manage`} component={ManageQualifications} />

      <Route path={`${match.url}dashboard/myaccount`} component={MyAppsHome} />
      <Route path={`${match.url}dashboard/executive`} component={ExecDashboard} />

      <Route path={`${match.url}dashboard/pmo/reports/timelog`} component={PmoTimelog} />
      <Route path={`${match.url}dashboard/pmo`} component={PMODashboard} />

      { userRoles.some((role) => role.id === 3) && <Route path={`${match.url}dashboard/manager/reports/timelog`} component={MgrTimelog} />}
      { userRoles.some((role) => role.id === 3) && <Route path={`${match.url}dashboard/manager`} component={ManagerDashboard} />}

      <Route path={`${match.url}dashboard/employee`} component={EmployeeDashboard} />
      {userDetails.isReportingManager && <Route path="/timesheets/rmapprove/by-id/:rmid/:id/:year/:week" component={RMApproveTimeById} />}

      <Route path="/timesheets/submit/by-id/:year/:week" component={SubmitTimeById} />
      <Route path={`${match.url}timesheets/submit/new`} component={NewSubmitTime} />
      <Route path={`${match.url}timesheets/submit`} component={SubmitTime} />
      { userRoles.some((role) => role.id === 3) && <Route path={`${match.url}timesheets/approve`} component={ApproveTime} />}
      { userDetails.isReportingManager && <Route path={`${match.url}timesheets/rmapprove`} component={RMApproveTime} />}
      { userDetails.isReportingManager && <Route path={`${match.url}dashboard/reportingmanager/projects`} component={RMDashboardProjects} />}
      { userDetails.isReportingManager && <Route path={`${match.url}dashboard/reportingmanager/tasks`} component={RMDashboardTasks} />}

      { userRoles.some((role) => role.id === 3 || role.id === 2) && <Route path="/weeklystatus/manage/:id" component={EditWSR} />}
      { userRoles.some((role) => role.id === 3 || role.id === 2) && <Route path={`${match.url}weeklystatus/manage`} component={ManageWSR} />}
      { userRoles.some((role) => role.id === 3 || role.id === 2) && <Route path={`${match.url}weeklystatus/new`} component={NewWSR} />}

      { userRoles.some((role) => role.id === 2) && <Route path="/issues/manage/:id" component={EditIssue} />}
      { userRoles.some((role) => role.id === 2) && <Route path={`${match.url}issues/manage`} component={ManageIssue} />}
      { userRoles.some((role) => role.id === 2) && <Route path={`${match.url}issues/new`} component={NewIssue} />}

      { userRoles.some((role) => role.id === 3 || role.id === 2) && <Route path="/resources/manage/:id" component={EditRescAlloc} />}
      { userRoles.some((role) => role.id === 3 || role.id === 2) && <Route path={`${match.url}resources/manage`} component={ManageRescAlloc} />}
      { userRoles.some((role) => role.id === 3 || role.id === 2) && <Route path={`${match.url}resources/new`} component={NewRescAlloc} />}

      { userRoles.some((role) => role.id === 2) && <Route path="/cost/manage/:id" component={EditCost} />}
      { userRoles.some((role) => role.id === 2) && <Route path={`${match.url}cost/manage`} component={ManageCost} />}
      { userRoles.some((role) => role.id === 2) && <Route path={`${match.url}cost/new`} component={NewCost} />}

      { userRoles.some((role) => role.id === 2) && <Route path="/plan/manage/:id" component={EditPlan} />}
      { userRoles.some((role) => role.id === 2) && <Route path={`${match.url}plan/manage`} component={ManagePlan} />}
      { userRoles.some((role) => role.id === 2) && <Route path={`${match.url}plan/new`} component={NewPlan} />}

      <Route path="/master/projects/efforts/manage/:id" component={EditPlannedEfforts} />
      <Route path={`${match.url}master/projects/efforts/manage`} component={ManageProjEfforts} />
      <Route path={`${match.url}master/projects/efforts/new`} component={NewProjEfforts} />

      <Route path="/master/projects/resourceplan/manage/:id" component={EditResPlan} />
      <Route path={`${match.url}master/projects/resourceplan/manage`} component={ManageResPlan} />
      <Route path={`${match.url}master/projects/resourceplan/new`} component={NewResPlan} />

      <Route path="/master/projects/projplan/manage/:id" component={EditProjPlan} />
      <Route path={`${match.url}master/projects/projplan/manage`} component={ManageProjPlan} />
      <Route path={`${match.url}master/projects/projplan/new`} component={NewProjPlan} />

      <Route path={`${match.url}resourceloading/utilization`} component={ResourceUtil} />
      <Route path={`${match.url}resourceloading/allocation`} component={ResourceAlloc} />

      <Route path="/reports/project/by-id/:id" component={ProjectById} />
      <Route path={`${match.url}reports/wsr`} component={WSRReport} />
      <Route path={`${match.url}reports/issue`} component={IssuesReport} />
      <Route path={`${match.url}reports/resource`} component={RescAllocReport} />
      <Route path={`${match.url}reports/cost`} component={CostReport} />
      <Route path={`${match.url}reports/plan`} component={PlanReport} />
      <Route path={`${match.url}reports/plan/planGantt`} component={PlanGantt} />
      <Route path={`${match.url}reports/project`} component={ProjectsReport} />
      <Route path={`${match.url}reports/task`} component={TasksReport} />
      <Route path={`${match.url}reports/client`} component={ClientsReport} />
      <Route path={`${match.url}reports/orgstructure`} component={OrgStructureReport} />
      { userRoles.some((role) => role.id === 2) && <Route path={`${match.url}reports/timesheet/timelog`} component={TimelogReport} />}
      { userRoles.some((role) => role.id === 2) && <Route path={`${match.url}reports/timesheet`} component={TSReport} />}
      { userRoles.some((role) => role.id === 2) && <Route path={`${match.url}reset/userpasswordbypmo`} component={ResetPasswordByAdmin} />}
      { userRoles.some((role) => role.id === 2) && <Route path={`/timesheet/statuschange`} component={TimeSheetStatusChange} />}
      <Route path="/travel_desk/request" component={TravelDeskRequest} />
      { userRoles.some((role) => role.id === 3) && <Route path="/travel_desk/pm-approve" component={TravelDeskPMApprove} />}
      {userDetails.isReportingManager && <Route path="/travel_desk/rm-approve" component={TravelDeskRMApprove} />}
      {userRoles.some((role) => role.id === 8) && <Route path="/travel_desk/admin-approve" component={TravelAdminApprove} />}
      
      <Route path="/**" component={UnAuthorized} />

    </Switch>
  </div>
  )
};
export default App;