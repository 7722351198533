import React from "react";
import { Input, Button } from "antd";
import Highlighter from "react-highlight-words";
import axios from 'axios';
import { API_URL } from 'constants/APIConfig';
import { FormOutlined, FileAddTwoTone } from '@ant-design/icons';
import { SearchOutlined } from '@ant-design/icons';
import ProjStats from "../utils/ProjStats";
import Auxiliary from "util/Auxiliary";
import get from "lodash.get";
import isequal from "lodash.isequal";
import moment from "moment";
import { Table } from "ant-table-extensions";

class ManageProjects extends React.Component {

  state = {
    searchText: "",
    searchedColumn: "",
    projects: [],
    loading: false,
    userDetails: JSON.parse(sessionStorage.getItem('user_details')),
    token: sessionStorage.getItem('token'),
  };

  getAllprojects = (statusFilter, typeFilter) => {
    this.setState({ loading: true });
    axios.get(API_URL + `/api/project/v2/all?filter=${statusFilter}&typeFilter=${typeFilter}&userId=1&limit=500&offset=0`,
      {
        headers: {
          'x-access-token': `${this.state.token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        if (Object.keys(response.data.data).length > 0) {
          //console.log("ProjectList", response.data.data)
          let data = [];
          response.data.data.map((item, index) => {
            data.push({
              id: item.id,
              //name: item.shortName,
              name: (item.name).toUpperCase(),
              projectcode: item.projectCode,
              projtype: item.type && item.type.typeTx,
              client: item.client && item.client.organization,
              //sdt: item.startDate,
              estdhrs: item.estimatedHours,
              sdt: moment(item.startDate).format('DD-MM-YYYY'),
              edt: moment(item.endDate).format('DD-MM-YYYY'),
              progress: item.progress && item.progress,
              statusTx: item.status && item.status.statusTx,
              accmgr: item.AccountManager && item.AccountManager.displayNameTx,
              projmgr: item.ProjectManager && item.ProjectManager.displayNameTx,
              paymode: item.BillableType && item.BillableType.name,
              closedDate: item.closedDate && moment(item.closedDate).format('DD-MM-YYYY'),
              key: item.id
            })
          })
          this.setState({ projects: data });
          this.setState({ loading: false });
        }
      })
      .catch(error => {
        // Error
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
        console.log(error.config);
      });
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div className="custom-filter-dropdown">
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}

          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 70, marginRight: 8, float: 'left' }}
        >
          {/*   Search */}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 70, float: 'right' }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined />
    ),
    /*     onFilter: (value, record) =>
          record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()), */
    onFilter: (value, record) => {
      return get(record, dataIndex)
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },

    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    /*     render: text => (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        ) */
    render: text => {
      return isequal(this.state.searchedColumn, dataIndex) ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      );
    }
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
    //this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  componentDidMount() {
    this.getAllprojects();
  }

  render() {
    //const { history } = this.props;
    const columns = [
      {
        title: 'Name', dataIndex: 'name', key: 'name', fixed: 'left', width: 200,
        sorter: (a, b) => String(a.name).localeCompare(String(b.name)),
        sortDirections: ['descend', 'ascend'],
        ...this.getColumnSearchProps("name")
      },
      {
        title: 'Code', dataIndex: 'projectcode', key: 'projectcode', fixed: 'left', width: 200,
        sorter: (a, b) => String(a.projectcode).localeCompare(String(b.projectcode)),
        sortDirections: ['descend', 'ascend'],
        ...this.getColumnSearchProps("projectcode")
      },
      {
        title: 'Start Date', dataIndex: 'sdt', key: 'sdt', width: 150,
        sorter: (a, b) => String(a.sdt).localeCompare(String(b.sdt)),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'End Date', dataIndex: 'edt', key: 'edt', width: 150,
        sorter: (a, b) => String(a.edt).localeCompare(String(b.edt)),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Project Type', dataIndex: 'projtype', key: 'projtype', width: 170,
        sorter: (a, b) => String(a.projtype).localeCompare(String(b.projtype)),
        sortDirections: ['descend', 'ascend'],
        filters: [
          {
            text: 'Internal',
            value: 'Internal',
          },
          {
            text: 'External',
            value: 'External',
          },
        ],
        /*    ...this.getColumnSearchProps("projtype") */
        //onFilter: (value, record) => record.projtype.indexOf(value) === 0,
        onFilter: (value, record) => record.projtype ? record.projtype.toLowerCase().includes(value.toLowerCase()) : false,
      },
      {
        title: 'Payment Mode', dataIndex: 'paymode', key: 'paymode', width: 180,
        sorter: (a, b) => String(a.paymode).localeCompare(String(b.paymode)),
        sortDirections: ['descend', 'ascend'],
        filters: [
          {
            text: 'SOW',
            value: 'SOW',
          },
          {
            text: 'Monthly',
            value: 'Monthly',
          },
          {
            text: 'Quarterly',
            value: 'Quarterly',
          },
          {
            text: 'Project',
            value: 'Project',
          },
          {
            text: 'Milestone',
            value: 'Milestone',
          },
          {
            text: 'Annually',
            value: 'Annually',
          },

        ],
        /*  onFilter: (value, record) => record.paymode.indexOf(value) === 0, */
        onFilter: (value, record) => record.paymode ? record.paymode.toLowerCase().includes(value.toLowerCase()) : false,
      },
      {
        title: 'status', dataIndex: 'statusTx', key: 'statusTx', width: 150,
        sorter: (a, b) => String(a.statusTx).localeCompare(String(b.statusTx)),
        sortDirections: ['descend', 'ascend'],
        filters: [
          {
            text: 'InProgress',
            value: 'InProgress',
          },
          {
            text: 'New',
            value: 'New',
          },
          {
            text: 'Completed',
            value: 'Completed',
          },
          {
            text: 'Hold',
            value: 'Hold',
          },
          {
            text: 'Archive',
            value: 'Archive',
          },

        ],
        /*  onFilter: (value, record) => record.paymode.indexOf(value) === 0, */
        onFilter: (value, record) => record.statusTx ? record.statusTx.toLowerCase().includes(value.toLowerCase()) : false,
      },
      {
        title: 'Estd. Hours', dataIndex: 'estdhrs', key: 'estdhrs', width: 150,
        sorter: (a, b) => String(a.estdhrs).localeCompare(String(b.estdhrs)),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Progress', dataIndex: 'progress', key: 'progress', width: 150,
        sorter: (a, b) => String(a.progress).localeCompare(String(b.progress)),
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Closed Date', dataIndex: 'closedDate', key: 'closedDate', width: 150,
        sorter: (a, b) => String(a.closedDate).localeCompare(String(b.closedDate)),
        sortDirections: ['descend', 'ascend'],
      },

      {
        title: 'Acct Mgr', dataIndex: 'accmgr', key: 'accmgr', width: 200,
        sorter: (a, b) => String(a.accmgr).localeCompare(String(b.accmgr)),
        sortDirections: ['descend', 'ascend'],
        ...this.getColumnSearchProps("accmgr")
      },
      {
        title: 'Proj Mgr', dataIndex: 'projmgr', key: 'projmgr', width: 200,
        sorter: (a, b) => String(a.projmgr).localeCompare(String(b.projmgr)),
        sortDirections: ['descend', 'ascend'],
        ...this.getColumnSearchProps("projmgr")
      },
      {
        title: 'Client', dataIndex: 'client', key: 'client', width: 200,
        sorter: (a, b) => String(a.client).localeCompare(String(b.client)),
        sortDirections: ['descend', 'ascend'],
        ...this.getColumnSearchProps("client")
      },
      { title: 'Action', fixed: 'right', width: 100, dataIndex: 'id', render: text => <div><span> <a href={'/master/projects/manage/' + text}><FormOutlined style={{ padding: '10px' }} /></a></span> </div> },
    ];
    return (
      <Auxiliary>
        <ProjStats />
        <div className="newprojectBtn"><span> <a href={'/master/projects/new'}><FileAddTwoTone style={{ padding: '10px' }} /> New Project</a></span> </div>
        <Table className="mRemove-fixCell" columns={columns} dataSource={this.state.projects} pagination={{ pageSize: 10 }} scroll={{ x: 1300 }}
          loading={this.state.loading}
          exportable
          exportableProps={{ showColumnPicker: true }}
        />
      </Auxiliary>
    );
  }
}
export default ManageProjects;
